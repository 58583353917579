import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FONTS from '../../../constants/fonts';
import { shape, func, string } from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import Grid from '@material-ui/core/Grid';
import Headzup from '../../../images/Logo/HeadzupSport_logo2.png';
import Header from '../../Header';

const useStyles = makeStyles(theme => ({
  table: {
    width: '100%',
    margin: 15,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      marginTop: 10,
      marginLeft: 20,
    },
  },

  cellsText: {
    fontWeight: FONTS.WEIGHT.LIGHT,
    margin: 10,
    fontSize: 13,
    verticalAlign: 'top',
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      paddingRight: 10,
    },
  },
  headerText: {
    paddingRight: 36,
    fontSize: 13,
    width: 100,
    verticalAlign: 'top',
    [theme.breakpoints.down('xs')]: {
      paddingRight: 20,
    },
  },
}));

export const StartSide = ({
  intl,
  img,
  img2,
  templateStyles,
}) => {
  const { formatMessage } = intl;
  const classes = {
    ...templateStyles(),
    ...useStyles(),
  };
  const t = id => formatMessage({ id });

  return (
    <Grid container spacing={5} className={classes.root}>
      <Grid
        item
        xs={12}
        xl={8}
        style={{ marginBottom: 20 }}
      >
        {/* <Header>{t('AboutUsStart.title')}</Header> */}
        <img src={Headzup} className={classes.logo} />
        <p className={classes.text}>
          {t('AboutUsStart.introText')}
        </p>
      </Grid>
      <Grid item xs={12} xl={8}>
        <Grid container spacing={5}>
          <Grid
            item
            xs={12}
            md={5}
            className={classes.imgContainer}
          >
            <img
              src={img}
              alt={t('ImagesAltText.imagePierre')}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid
              container
              spacing={4}
              style={{ marginBottom: 20 }}
            >
              <Grid item xs={12}>
                <Header secondary>
                  {t('AboutUsStart.nameValue')}
                </Header>
                <p className={classes.text}>
                  {t('AboutUsStart.background')}
                </p>
                <table className={classes.table}>
                  <tbody>
                    <tr>
                      <td className={classes.headerText}>
                        {t(
                          'AboutUsStart.backgroundValueOne'
                        )}
                      </td>
                      <td className={classes.headerText}>
                        {t(
                          'AboutUsStart.backgroundValueDoneOne'
                        )}
                      </td>
                      <td className={classes.headerText}>
                        {t(
                          'AboutUsStart.backgroundValueYearsOne'
                        )}
                      </td>
                    </tr>

                    <tr>
                      <td className={classes.headerText}>
                        {t(
                          'AboutUsStart.backgroundValueTwo'
                        )}
                      </td>
                      <td className={classes.headerText}>
                        {t(
                          'AboutUsStart.backgroundValueDoneTwo'
                        )}
                      </td>
                      <td className={classes.headerText}>
                        {t(
                          'AboutUsStart.backgroundValueYearsTwo'
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className={classes.headerText}>
                        {t(
                          'AboutUsStart.backgroundValueThree'
                        )}
                      </td>
                      <td className={classes.headerText}>
                        {t(
                          'AboutUsStart.backgroundValueDoneThree'
                        )}
                      </td>
                      <td className={classes.headerText}>
                        {t(
                          'AboutUsStart.backgroundValueYearsThree'
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
              <Grid item xs={12}>
                <p
                  className={classes.text}
                  style={{ marginTop: 0 }}
                >
                  {t('AboutUsStart.textAboutPierre')}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Header secondary>
                  {t('AboutUsStart.titleForma')}
                </Header>
                <p className={classes.text}>
                  {t('AboutUsStart.textAboutFormaOne')}
                  <a
                    href="http://ibforma.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    ibforma.com
                  </a>
                  .
                </p>
              </Grid>
              <Grid item xs={12}>
                <Header secondary>
                  {t('AboutUsStart.subHeader')}
                </Header>
                <p className={classes.text}>
                  {t('AboutUsStart.textAboutForma')}
                </p>
                {/* <p className={classes.quote}>
                  {t('AboutUsStart.quote')}
                </p> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            className={classes.imgContainer}
          >
            <img
              src={img2}
              alt={t('ImagesAltText.imagePierre')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

StartSide.propTypes = {
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
  img: string,
  img2: string,
  templateStyles: func.isRequired,
};

StartSide.defaultProps = {
  img: '',
  img2: '',
};

export default injectIntl(StartSide);
