import React from 'react';
import Layout from '../components/layout';
import Hub from '../components/AboutUs/Hub';
import { graphql } from 'gatsby';
import { injectIntl } from 'gatsby-plugin-intl';
import SEO from '../components/seo';
import { Helmet } from 'react-helmet';
import { shape } from 'prop-types';
import PageContainer from '../components/Containers/PageContainer';

const AboutUs = ({ data }) => {
  const siteTitle = data.site.siteMetadata.title;
  const news = data.allContentfulBlogPost.edges;

  return (
    <Layout>
      <SEO
        title="HeadzUp Sport AB"
        description="En revolutionerade produkt för ishockeytränande ungdomar. Träna ishockey med headzup och utveckla din teknik och spelförståelse samt undvik tacklingar."
        keywords={['headzup']}
      />
      <Helmet title={siteTitle} />
      <PageContainer>
        <Hub news={news} />
      </PageContainer>
    </Layout>
  );
};

AboutUs.propTypes = {
  data: shape().isRequired,
};

export default injectIntl(AboutUs);
export const pageQuery = graphql`
  query AboutUsQuery {
    allContentfulBlogPost(
      sort: { fields: [publishDate], order: DESC }
    ) {
      edges {
        node {
          title
          introText
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          postType
          linkUrl
          heroImage {
            fluid(
              maxWidth: 350
              maxHeight: 196
              resizingBehavior: SCALE
            ) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
