import React from 'react';
import { shape, arrayOf, string } from 'prop-types';
import NewsCard from '../Cards/NewsCard';
import Slider from './Slider';

const NewsCardSlider = ({ cardsData, settings }) => {
  return (
    <>
      {/*TODO:Change key value. */}
      {/*TODO:Check if data prop is best to use instead of the rest of NewsCard props.*/}
      {cardsData && (
        <Slider settings={settings}>
          {cardsData.map((item, i) => (
            <NewsCard key={i} data={item.node} />
          ))}
        </Slider>
      )}
    </>
  );
};
NewsCardSlider.propTypes = {
  cardsData: arrayOf(
    shape({
      node: shape({
        title: string,
        shortDescription: string,
        heroImage: shape({
          fluid: shape(),
        }),
      }),
    })
  ).isRequired,
  settings: shape(),
};

export default NewsCardSlider;
