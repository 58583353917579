import React from 'react';
import Box from '@material-ui/core/Box';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { makeStyles } from '@material-ui/core/styles';
import { string, object } from 'prop-types';
import DefaultButton from '../Buttons/DefaultButton';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { injectIntl } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

const useStyle = makeStyles(theme => ({
  root: {
    textDecoration: 'none !important',
    display: 'inline-block',
    width: 'fit-content',
    border: `3px solid transparent`,
    [theme.breakpoints.down('xs')]: {
      border: `1px solid transparent`,
    },
    '&:hover': {
      border: `3px solid ${COLORS.SILVER}`,
      [theme.breakpoints.down('xs')]: {
        border: `1px solid ${COLORS.SILVER}`,
      },
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: 280,
    height: 450,
    [theme.breakpoints.down(800)]: {
      width: 250,
    },
    [theme.breakpoints.down(410)]: {
      width: 200,
    },
    [theme.breakpoints.down(325)]: {
      width: '100%',
    },
  },
  image: {
    width: '100%',
  },
  text: {
    color: COLORS.WHITE,
    margin: '0px 10px 10px 10px',
    lineHeight: 'normal',
    fontSize: FONTS.SIZE.XS,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14,
    },
  },
  button: {
    height: 50,
    marginLeft: 20,
    marginBottom: -5,
    width: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      margin: '0 auto',
      marginBottom: -10,
    },
  },
  date: {
    fontSize: FONTS.SIZE.XXS,
    color: COLORS.WHITE,
    margin: 10,
  },
  content: {
    flex: 0.9,
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingBottom: 10,
    margin: 10,
    [theme.breakpoints.down('xs')]: {
      margin: 3,
      padding: 0,
    },
  },
  header: {
    color: COLORS.WHITE,
    opacity: 0.8,
    margin: '10px 10px 8px 10px',
    fontWeight: FONTS.WEIGHT.NORMAL,
    fontSize: FONTS.SIZE.XXS,
    [theme.breakpoints.down('xs')]: {
      fontSize: FONTS.SIZE.XXXS,
    },
  },
}));

/*TODO:This component can be improved */

const NewsCard = ({
  intl,
  title,
  image,
  text,
  date,
  backgroundColor,
  data,
}) => {
  const { formatMessage } = intl;
  const classes = useStyle();

  const textData = data.introText;

  const textChange =
    textData.length > 72
      ? textData.slice(0, 72).concat('...')
      : textData;

  const ConditionalWrapper = ({ children }) =>
    data.linkUrl ? (
      <a
        href={data.linkUrl}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.root}
      >
        {children}
      </a>
    ) : (
      <Link
        to={`/blog/${data.slug}`}
        className={classes.root}
      >
        {children}
      </Link>
    );

  return (
    <ConditionalWrapper>
      <Box
        style={{ backgroundColor }}
        classes={{ root: classes.card }}
        elevation={1}
      >
        <Img
          className={classes.image}
          fluid={data.heroImage.fluid}
        />
        <div className={classes.content}>
          <p className={classes.header}>
            {data.postType.toUpperCase()}
          </p>
          <p className={classes.text}>{textChange}</p>
          <p className={classes.date}>{data.publishDate}</p>
        </div>
        <DefaultButton className={classes.button}>
          {formatMessage({
            id: 'NewsCard.button',
          })}
          <TrendingFlatIcon />
        </DefaultButton>
      </Box>
    </ConditionalWrapper>
  );
};

NewsCard.propTypes = {
  title: string,
  image: string,
  backgroundColor: string,
  text: string,
  date: string,
  intl: object.isRequired,
};

NewsCard.defaultProps = {
  title: '',
  backgroundColor: COLORS.BLACK,
  image: '',
  text: 'No text recived',
  date: 'No date recived',
};

export default injectIntl(NewsCard);
