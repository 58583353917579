import React from 'react';
import {
  makeStyles,
  useTheme,
} from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { shape, func, string, array } from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import FONTS from '../../../constants/fonts';
import NewsCardSlider from '../../Sliders/NewsCardSlider';
import nollVision from '../../../images/Tabloggor/nollvision_logo.png';
import Header from '../../Header';

const useStyles = makeStyles(theme => ({
  smallContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: 20,
    paddingLeft: 20,
    [theme.breakpoints.down('xs')]: {
      flexFlow: 'column',
      alignContent: 'center',
    },
    '&>h3': {
      fontSize: FONTS.SIZE.XM,
    },
    '&>p': { paddingLeft: 20 },
  },
  quoteContainer: {
    maxWidth: 650,
  },
  quoteSrc: {
    fontSize: FONTS.SIZE.XXS,
    width: '80%',
    textAlign: 'center',
    margin: '0 auto',
  },
  customQuote: { margin: '0 auto' },
}));

const Vision = ({
  intl,
  news,
  img,
  img2,
  templateStyles,
}) => {
  const classes = {
    ...templateStyles(),
    ...useStyles(),
  };
  const { formatMessage } = intl;
  const t = id => formatMessage({ id });
  const theme = useTheme();
  const smallScreen = useMediaQuery(
    theme.breakpoints.down('md')
  );
  return (
    <Grid container spacing={5} className={classes.root}>
      <Grid item xs={12} xl={8}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Grid
              container
              direction={
                smallScreen ? 'column-reverse' : 'row'
              }
              spacing={5}
            >
              <Grid
                item
                xs={12}
                md={5}
                className={classes.imgContainer}
              >
                <img
                  src={img}
                  alt={t('ImagesAltText.referee')}
                />
              </Grid>
              <Grid item xs={12} md={7}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <img
                      className={classes.logo}
                      src={nollVision}
                      alt={t(
                        'AboutUsVision.sections.0.title'
                      )}
                    />
                    <p className={classes.text}>
                      {t(
                        'AboutUsVision.sections.0.subText1'
                      )}
                    </p>
                  </Grid>
                  <Grid item xs={12}>
                    <div className={classes.quoteContainer}>
                      <p
                        className={`${classes.customQuote} ${classes.quote}`}
                      >
                        {t(
                          'AboutUsVision.sections.0.quote'
                        )}
                      </p>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <p className={classes.text}>
                      {t(
                        'AboutUsVision.sections.0.subText2'
                      )}
                    </p>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <Header secondary>
                  {t('AboutUsVision.sections.1.title')}
                </Header>
                <p className={classes.text}>
                  {t('AboutUsVision.sections.1.subtext1')}
                </p>
              </Grid>
              <Grid item xs={12}>
                <div className={classes.quoteContainer}>
                  <p
                    className={`${classes.customQuote} ${classes.quote}`}
                  >
                    {t('AboutUsVision.sections.1.quote')}
                  </p>
                  <p className={classes.quoteSrc}>
                    {t('AboutUsVision.sections.1.quoteSrc')}
                  </p>
                </div>
              </Grid>
              <Grid item xs={12} sm={10}>
                <div className={classes.smallContainer}>
                  <Header sub secondary>
                    # Misson
                  </Header>
                  <p>
                    {t(
                      'AboutUsVision.sections.1.missionText'
                    )}
                  </p>
                </div>
                <div className={classes.smallContainer}>
                  <Header sub secondary>
                    # Vision
                  </Header>
                  <p>
                    {t(
                      'AboutUsVision.sections.1.visionText'
                    )}
                  </p>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            className={classes.imgContainer}
          >
            <img
              src={img2}
              alt={t('ImagesAltText.referee')}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Header secondary>
          {t('AboutUsVision.cardHeader')}
        </Header>
      </Grid>
      <Grid item xs={12}>
        <NewsCardSlider
          cardsData={news}
          settings={{
            dots: true,
            responsive: [
              {
                breakpoint: 2025,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 1550,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 1125,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 850,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          }}
        />
      </Grid>
    </Grid>
  );
};

Vision.propTypes = {
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
  news: array,
  img: string,
  img2: string,
  templateStyles: func.isRequired,
};

Vision.defaultProps = {
  img: '',
  img2: '',
};

export default injectIntl(Vision);
