import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { string, object } from 'prop-types';
import COLORS from '../../constants/colors';
import FONTS from '../../constants/fonts';
import { injectIntl } from 'gatsby-plugin-intl';
import Img from 'gatsby-image';

const useStyle = makeStyles(theme => ({
  root: {
    textDecoration: 'none !important',
    display: 'inline-block',
    border: `3px solid ${COLORS.BLACK}00`,
    [theme.breakpoints.down('xs')]: {
      border: `1px solid ${COLORS.BLACK}00`,
    },
    '&:hover': {
      border: `3px solid ${COLORS.SILVER}`,
      [theme.breakpoints.down('xs')]: {
        border: `1px solid ${COLORS.SILVER}`,
      },
    },
  },
  card: {
    margin: 20,
    width: 250,
    height: 470,
    [theme.breakpoints.down('sm')]: {
      width: 200,
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      height: 'auto',
      fontSize: 10,
      margin: 0,
    },
  },
  image: {
    width: '100%',
    height: '40%',
  },
  text: {
    color: COLORS.WHITE,
    margin: '0px 5px 5px 5px',
    fontSize: FONTS.SIZE.XS,
    flex: 1,
    '-webkit-line-clamp': 8,
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkit-box-orient': 'vertical',
    '&:before': {
      content: '"“"',
    },
    '&:after': {
      content: '"”"',
      display: 'inline-block',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    paddingBottom: 10,
    margin: 10,
    padding: 15,
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      padding: 0,
    },
  },
  header: {
    color: 'white',
    opacity: 0.8,
    margin: '10px 10px 8px 10px',
    fontWeight: FONTS.WEIGHT.NORMAL,
    fontSize: FONTS.SIZE.XXS,
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
}));

const TeamReviewCard = ({
  intl,
  title,
  imgFluid,
  text,
  backgroundColor,
}) => {
  const { formatMessage } = intl;
  const classes = useStyle();

  return (
    <Paper
      style={{ backgroundColor }}
      className={classes.card}
      elevation={1}
      square
    >
      <Img className={classes.image} fluid={imgFluid} />
      <div className={classes.content}>
        <p className={classes.header}>
          {title.toUpperCase()}
        </p>
        <p className={classes.text}>{text}</p>
      </div>
    </Paper>
  );
};

TeamReviewCard.propTypes = {
  title: string,
  imgFluid: string,
  backgroundColor: string,
  text: string,
  date: string,
  intl: object.isRequired,
};
TeamReviewCard.defaultProps = {
  title: '',
  backgroundColor: COLORS.BLACK,
  imgFluid: '',
  text: 'No text recived',
  date: 'No date recived',
};

export default injectIntl(TeamReviewCard);
