import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import COLORS from '../../../constants/colors';
import Grid from '@material-ui/core/Grid';
import { shape, func, string } from 'prop-types';
import DefaultButton from '../../Buttons/DefaultButton';
import { injectIntl } from 'gatsby-plugin-intl';
import { Link, navigate } from 'gatsby';
import FTwentyLoggo from '../../../images/Tabloggor/F20_logo.png';
import Youtube from '../../Video/Youtube';
import PdfViewer from '../../Modals/PdfViewer';
import MANUAL from '../../../assets/documents/HeadzUP-manual-A4-min.pdf';
import Header from '../../Header';

const useStyles = makeStyles({
  buyButton: { maxWidth: 200, height: '100%' },
  link: {
    textDecoration: 'underline',
    width: 'fit-content',
    cursor: 'pointer',
    fontWeight: 600,
    display: 'flex',
    marginLeft: 'auto',
    '&:hover': {
      color: COLORS.RED,
    },
  },
});

export const FTwenty = ({
  intl,
  img,
  img2,
  templateStyles,
}) => {
  const classes = {
    ...templateStyles(),
    ...useStyles(),
  };
  const { formatMessage } = intl;
  const t = id => formatMessage({ id });

  return (
    <Grid container spacing={5} className={classes.root}>
      <Grid xs={12} xl={8} item>
        <img className={classes.logo} src={FTwentyLoggo} />
        <p className={classes.text}>
          {t('AboutUsVB.introText')}
        </p>
      </Grid>
      <Grid item xs={12} xl={8}>
        <Grid container spacing={5}>
          <Grid
            item
            xs={12}
            md={5}
            className={classes.imgContainer}
          >
            <img
              src={img}
              alt={t('ImagesAltText.peopleWithHelmets')}
            />
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Header secondary>
                  {t('AboutUsVB.subHeadOne')}
                </Header>
                <p className={classes.text}>
                  {t('AboutUsVB.subContentOne')}
                </p>
              </Grid>
              <Grid item xs={12}>
                <Youtube
                  videoUrl="https://www.youtube.com/watch?v=SeZlq4QMUAE"
                  customTriggerElement={props => (
                    <a {...props} className={classes.link}>
                      {t('AboutUsVB.video')}
                    </a>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Header secondary>
                  {t('AboutUsVB.subHeadFour')}
                </Header>
                <p className={classes.text}>
                  {t('AboutUsVB.subContentFour')}
                </p>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={7}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Header secondary>
                  {t('AboutUsVB.subHeadTwo')}
                </Header>
                <p className={classes.text}>
                  {t('AboutUsVB.subContentTwo')}
                </p>
              </Grid>
              <Grid item xs={12}>
                <Header secondary>
                  {t('AboutUsVB.subHeadThree')}
                </Header>
                <p className={classes.text}>
                  {t('AboutUsVB.subContentThree')}
                </p>
              </Grid>
              <Grid item xs={6}>
                <DefaultButton
                  onClick={() => {
                    navigate('/webshop');
                  }}
                  className={classes.buyButton}
                >
                  {t('AboutUsVB.buttonText')}
                </DefaultButton>
              </Grid>
              <Grid item xs={6}>
                <PdfViewer
                  url={MANUAL}
                  triggerElement={props => (
                    <a {...props} className={classes.link}>
                      {t('AboutUsVB.linkManual')}
                    </a>
                  )}
                />
                <Link
                  className={classes.link}
                  to="/#reviews"
                >
                  {t('AboutUsVB.linkReview')}
                </Link>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            className={classes.imgContainer}
          >
            <img
              src={img2}
              alt={t('ImagesAltText.boyHeadzUp')}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

FTwenty.propTypes = {
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
  img: string,
  img2: string,
  templateStyles: func.isRequired,
};

FTwenty.defaultProps = {
  img: '',
  img2: '',
};

export default injectIntl(FTwenty);
