import React, { useState } from 'react';
import { shape, func, array } from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Box } from '@material-ui/core';
import FTwenty from './TabContent/FTwenty';
import COLORS from '../../constants/colors';
import StartSide from './TabContent/StartSide';
import { injectIntl } from 'gatsby-plugin-intl';
import TestPic from '../../images/64x64.png';
import Team from './TabContent/Team';
import Vision from './TabContent/Vision';
import Headzup from '../../images/Tabloggor/HeadzupSport_logo.png';
import F20 from '../../images/Tabloggor/F20_logo.png';
import U16 from '../../images/Tabloggor/U16_logo.png';
import Nollvision from '../../images/Tabloggor/nollvision_logo.png';
import Pierre from '../../images/About-us/Pierre-Johnsson.jpg';
import Cage from '../../images/About-us/mask-cage3.jpg';
import f20img1 from '../../images/About-us/f20img1.jpg';
import f20img2 from '../../images/About-us/f20img2.jpg';
import nollVision1 from '../../images/About-us/nollVision1.jpg';
import nollVision2 from '../../images/About-us/nollVision2.jpg';
import FONTS from '../../constants/fonts';

const useStyles = makeStyles(theme => ({
  root: {
    color: COLORS.WHITE,
    '& .MuiTabs-indicator': { display: 'none' },
  },
  button: {
    margin: 5,
    padding: 10,
    height: 160,
    backgroundImage: `linear-gradient(to bottom right, ${COLORS.DARK_BLUE}, ${COLORS.BLACK})`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'outset',
    borderWidth: '3px',
    borderColor: COLORS.BLUE,
    '&.Mui-selected': {
      backgroundImage: 'none',
      backgroundColor: COLORS.RED,
      borderColor: COLORS.LIGHT_RED,
    },
    [theme.breakpoints.down('xs')]: {
      height: 70,
    },
  },
  logos: {
    width: '63%',
    height: '30%',
    [theme.breakpoints.down('xs')]: {
      width: '90%',
      height: '25px',
    },
  },
  slider: {
    marginTop: 90,
    marginLeft: 10,
    marginRight: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: 50,
    },
  },
  slide: { overflow: 'hidden !important' },
}));

const templateStyles = makeStyles(theme => ({
  root: {
    justifyContent: 'center',
  },
  logo: { width: 160 },
  text: {
    marginTop: 20,
    paddingLeft: 20,
    lineHeight: 1.5,
    maxWidth: 650,
    whiteSpace: 'pre-line',
  },
  imgContainer: {
    [theme.breakpoints.up('xl')]: {
      maxWidth: 'fit-content',
    },
    '& >*': {
      maxWidth: 450,
      [theme.breakpoints.down('sm')]: {
        margin: 'auto',
      },
    },
  },
  quote: {
    lineHeight: 1.7,
    paddingTop: 30,
    fontStyle: 'italic',
    fontWeight: FONTS.WEIGHT.BOLD,
    margin: 'auto',
    width: '80%',
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
}));

const Hub = ({ intl, news }) => {
  const classes = useStyles();
  const [shownSlideIndex, setShownSlideIndex] = useState(0);
  const { formatMessage } = intl;

  const handleChange = newIndex => {
    setShownSlideIndex(newIndex);
  };

  const tabs = [
    {
      content: (
        <StartSide
          img={Pierre}
          img2={Cage}
          templateStyles={templateStyles}
        />
      ),
      label: 'HeadzUp',
      logo: Headzup,
    },
    {
      content: (
        <FTwenty
          img={f20img1}
          img2={f20img2}
          templateStyles={templateStyles}
        />
      ),
      label: 'VB-20',
      logo: F20,
    },
    {
      content: (
        <Team
          img={TestPic}
          templateStyles={templateStyles}
        />
      ),
      label: 'Färjestad BK',
      logo: U16,
    },
    {
      content: (
        <Vision
          news={news}
          img={nollVision1}
          img2={nollVision2}
          templateStyles={templateStyles}
        />
      ),
      label: formatMessage({
        id: 'AboutUsTab.vision',
      }),
      logo: Nollvision,
    },
  ];

  return (
    <div className={classes.root}>
      <Tabs
        value={shownSlideIndex}
        onChange={(e, i) => handleChange(i)}
        variant="fullWidth"
      >
        {tabs.map(item => (
          <Tab
            classes={{ root: classes.button }}
            key={item.label}
            label={
              <img
                className={classes.logos}
                src={item.logo}
                alt={item.label}
              />
            }
          />
        ))}
      </Tabs>
      <SwipeableViews
        axis="x"
        index={shownSlideIndex}
        onChangeIndex={handleChange}
        className={classes.slider}
        slideClassName={classes.slide}
      >
        {tabs.map((item, i) => (
          <Box
            hidden={shownSlideIndex !== i}
            key={item.label}
          >
            {item.content}
          </Box>
        ))}
      </SwipeableViews>
    </div>
  );
};

Hub.propTypes = {
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
  news: array,
};

export default injectIntl(Hub);
