import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { shape, func } from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import Youtube from '../../Video/Youtube';
import Slider from '../../Sliders/Slider';
import AboutUsCard from '../../Cards/TeamReviewCard';
import U16 from '../../../images/About-us/U16-faerjestad.png';
import Header from '../../Header';
import FONTS from '../../../constants/fonts';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const useStyles = makeStyles(theme => ({
  header: {
    paddingTop: 10,
    lineHeight: 1,
    marginLeft: 15,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
  },
  logo: {
    width: 300,
    height: '100%',
    [theme.breakpoints.between(351, 700)]: {
      width: 260,
    },
    [theme.breakpoints.down(350)]: {
      width: 260,
    },
  },
  overText: {
    fontSize: FONTS.SIZE.XM,
    [theme.breakpoints.down(800)]: {
      fontSize: FONTS.SIZE.S,
    },
  },
  underText: {
    fontSize: FONTS.SIZE.XL,
    [theme.breakpoints.down(400)]: {
      fontSize: FONTS.SIZE.M,
    },
    [theme.breakpoints.between(401, 701)]: {
      fontSize: FONTS.SIZE.XXM,
    },
    [theme.breakpoints.between(701, 900)]: {
      fontSize: FONTS.SIZE.L,
    },
  },
  videoContainer: {
    '&>div': {
      maxWidth: 520,
      maxHeight: 250,
      boxShadow: `2px 2px 5px rgba(20, 20, 20,0.5)`,
      [theme.breakpoints.down('sm')]: {
        margin: 'auto',
      },
    },
  },
}));

const Team = ({ intl, templateStyles }) => {
  const data = useStaticQuery(query);
  const classes = {
    ...templateStyles(),
    ...useStyles(),
  };
  console.log(classes.imgContainer);
  const { formatMessage } = intl;
  const t = id => formatMessage({ id });

  const cards = data.allContentfulReview.edges.map(
    ({
      node: {
        image,
        name,
        comment: { comment },
      },
    }) => {
      return (
        <AboutUsCard
          key={name}
          title={name}
          imgFluid={image && image.fluid}
          text={comment}
        />
      );
    }
  );

  return (
    <Grid container spacing={5} className={classes.root}>
      <Grid item xs={12} xl={8}>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>
              <Grid item>
                <img
                  className={classes.logo}
                  src={U16}
                  alt={t('AboutUsTeam.title')}
                />
              </Grid>
              <Grid item className={classes.header}>
                {/* <p className={classes.overText}>
                  {t('AboutUsTeam.title').split('\n')[0]}
                </p>
                <p className={classes.underText}>
                  {t('AboutUsTeam.title').split('\n')[1]}
                </p> */}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <p className={classes.text}>
              {t('AboutUsTeam.firstText')}
            </p>
          </Grid>
        </Grid>
      </Grid>

      {data.allContentfulPageSection.edges &&
        data.allContentfulPageSection.edges.map(
          (
            {
              node: {
                title,
                text: { text },
                youtubeVideoLink,
                image,
              },
            },
            i
          ) => (
            <Grid item xs={12} xl={8} key={text}>
              <Grid
                container
                spacing={5}
                style={{ marginBottom: 10, marginTop: 10 }}
                direction={
                  i % 2 === 0 ? 'row' : 'row-reverse'
                }
              >
                <Grid
                  item
                  xs={12}
                  md={5}
                  className={
                    image &&
                    image.fluid &&
                    !youtubeVideoLink &&
                    classes.imgContainer
                  }
                >
                  {youtubeVideoLink &&
                  youtubeVideoLink.length ? (
                    <Grid container spacing={2}>
                      {youtubeVideoLink.map(link => (
                        <Grid
                          item
                          className={classes.videoContainer}
                          xs={12}
                          key={link}
                        >
                          <Youtube videoUrl={link} />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    image &&
                    image.fluid && (
                      <Img fluid={image.fluid} />
                    )
                  )}
                </Grid>
                <Grid item xs={12} md={7}>
                  <Header secondary>{title}</Header>
                  <p
                    className={classes.text}
                    style={{ whiteSpace: 'pre-line' }}
                  >
                    {text}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          )
        )}
      <Grid item xs={12} xl={8}>
        <Header secondary>
          {t('AboutUsTeam.cardHead')}
        </Header>
      </Grid>
      <Grid item xs={12} xl={9}>
        <Slider
          settings={{
            dots: true,
            responsive: [
              {
                breakpoint: 2025,
                settings: {
                  slidesToShow: 4,
                },
              },
              {
                breakpoint: 1550,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 1125,
                settings: {
                  slidesToShow: 2,
                },
              },
              {
                breakpoint: 850,
                settings: {
                  slidesToShow: 1,
                },
              },
            ],
          }}
        >
          {cards}
        </Slider>
      </Grid>
    </Grid>
  );
};

Team.propTypes = {
  intl: shape({
    formatMessage: func.isRequired,
  }).isRequired,
  templateStyles: func.isRequired,
};

export default injectIntl(Team);

const query = graphql`
  query TeamQuery {
    allContentfulReview(
      filter: { visibleIn: { eq: "/about-us/u16" } }
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
          name
          comment {
            comment
          }
        }
      }
    }
    allContentfulPageSection(
      sort: { fields: updatedAt, order: DESC }
    ) {
      edges {
        node {
          title
          text {
            text
          }
          youtubeVideoLink
          image {
            fluid {
              ...GatsbyContentfulFluid_noBase64
            }
          }
        }
      }
    }
  }
`;
